import React from 'react';
import LocalForage from 'localforage';

const localForage = LocalForage.config({
    name: 'ecom',
    storeName: 'ecom_data', // Should be alphanumeric, with underscores.
    description: 'ecomdata',
});

// FIXME - This is doing nothing. `createInstance` is required to create an instance, not .config
const LocalStorageContext = React.createContext(localForage);

export {LocalStorageContext};
