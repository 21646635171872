import React from 'react';

export const customerContextInit: any = {
    initComplete: false,
    acceptedAgeGate: false,
    acceptedAgeGateDate: undefined,
    mutationId: undefined,
    customer: {},
};

interface CustomerData {
    initComplete: boolean;
    acceptedAgeGate: boolean;
    acceptedAgeGateDate?: Date;
    mutationId: string;
    customer: any;
}

interface Props {
    customerData: CustomerData;
    acceptAgeGate: (value: boolean) => void;
    updateCustomer: (customer: any) => void;
    updateMutationId: (value: string) => void;
}

const CustomerContext = React.createContext<Props>({
    customerData: customerContextInit,
    acceptAgeGate: null as any,
    updateCustomer: null as any,
    updateMutationId: null as any,
});

export {CustomerContext};
