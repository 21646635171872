/* eslint-disable */
import React from 'react';
import {CustomerProvider} from './src/modules/common/context/CustomerProvider';
import {SnackbarProvider} from 'notistack';
import {ApolloProvider} from '@apollo/react-common';
import client from './apollo.config';

// eslint-disable-next-line import/prefer-default-export
export default ({element}) => (
    <ApolloProvider client={client}>
        <CustomerProvider>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                {element}
            </SnackbarProvider>
        </CustomerProvider>
    </ApolloProvider>
);
