import {useContext} from 'react';
import {LocalStorageContext} from '../context/LocalStorageContext';
import {Cart} from '../../core/models/Cart';
import localforage from 'localforage';

const CART_KEY = 'ecom_cart';
const CUSTOMER_KEY = 'ecom_customer';
export function useLocalStorage() {
    const localForage = useContext(LocalStorageContext);

    const saveStorageCartData = (value: Cart) => localforage.setItem(CART_KEY, value);
    const getStorageCartData = (): Cart => localforage.getItem(CART_KEY) as any;
    const saveStorageCustomerData = (value: any) => localforage.setItem(CUSTOMER_KEY, value);
    const getStorageCustomerData = () => localforage.getItem(CUSTOMER_KEY);

    return {localForage, getStorageCartData, saveStorageCartData, getStorageCustomerData, saveStorageCustomerData};
}
