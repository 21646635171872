// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-tpl-tsx": () => import("../src/templates/PageTpl.tsx" /* webpackChunkName: "component---src-templates-page-tpl-tsx" */),
  "component---src-templates-blog-post-page-tpl-tsx": () => import("../src/templates/BlogPostPageTpl.tsx" /* webpackChunkName: "component---src-templates-blog-post-page-tpl-tsx" */),
  "component---src-templates-product-page-tsx": () => import("../src/templates/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-collection-page-tpl-tsx": () => import("../src/templates/CollectionPageTpl.tsx" /* webpackChunkName: "component---src-templates-collection-page-tpl-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blocked-tsx": () => import("../src/pages/blocked.tsx" /* webpackChunkName: "component---src-pages-blocked-tsx" */),
  "component---src-pages-cart-tsx": () => import("../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */)
}

