import React, {useEffect, useState} from 'react';
import {useLocalStorage} from '../../common/hooks/useLocalStorage';
import {CustomerContext, customerContextInit} from './CustomerContext';
import {uuidv4} from '../../core/util/UuidUtil';

interface Props {
    children: any;
}

export const CustomerProvider: React.FC<Props> = ({children}) => {
    const [customerData, setCustomerData] = useState(customerContextInit);
    const {getStorageCustomerData, saveStorageCustomerData} = useLocalStorage();

    const updateMutationId = (mutationId: string) => {
        console.log('updateMutationId:', mutationId);
        const newCustomerData = {...customerData, ...{mutationId}};
        saveStorageCustomerData(newCustomerData);
        setCustomerData(newCustomerData);
    };

    const restoreCustomerDataFromStorage = async () => {
        console.log('restoreCustomerDataFromStorage...');
        const savedData: any = await getStorageCustomerData();
        console.log('restoreCustomerDataFromStorage.savedData:', savedData);
        if (!savedData) {
            const newCustomerData = {
                ...customerData,
                ...{initComplete: true, mutationId: uuidv4()},
            };
            setCustomerData(newCustomerData);
            saveStorageCustomerData(newCustomerData);
            return;
        }
        if (!savedData.mutationId) {
            return updateMutationId(uuidv4());
        }
        setCustomerData(savedData);
    };

    useEffect(() => {
        restoreCustomerDataFromStorage();
    }, [false]);

    const acceptAgeGate = (agree: boolean) => {
        if (!agree) return;
        const newCustomerData = {
            ...customerData,
            ...{initComplete: true, acceptedAgeGate: agree, acceptedAgeGateDate: new Date()},
        };
        setCustomerData(newCustomerData);
        saveStorageCustomerData(newCustomerData);
        // console.log('acceptAgeGate:', agree, ' customerData:', newCustomerData);
    };

    const updateCustomer = (customer: any) => {
        const newCustomerData = {...customerData, ...{initComplete: true, customer}};
        setCustomerData(newCustomerData);
        saveStorageCustomerData(newCustomerData);
    };

    return (
        <CustomerContext.Provider value={{customerData, acceptAgeGate, updateCustomer, updateMutationId}}>
            {children}
        </CustomerContext.Provider>
    );
};
