import fetch from 'isomorphic-fetch';
import './src/modules/common/context/LocalStorageContext';
import localforage from 'localforage';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloClient} from 'apollo-client';
import {HttpLink} from 'apollo-link-http';
import {ApolloLink, fromPromise} from 'apollo-link';
import {setContext} from 'apollo-link-context';
import {IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';
import {onError} from 'apollo-link-error';
import {hasNetworkTokenExpiredError} from './src/modules/core/util/ErrorUtil';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
});

const cache = new InMemoryCache({fragmentMatcher});

const initData = {};

cache.writeData({data: initData});

const GRAPHQL_URL = `/graphql`;
// console.log('apollo.config-graphqlurl:', GRAPHQL_URL);

const WOO_COMMERCE_HEADER = 'woocommerce-session';
const LS_SESSION_KEY = 'ecom_session';

const httpLink = new HttpLink({
    uri: GRAPHQL_URL,
    fetch,
});

const logout = async () => {
    await localforage.removeItem(LS_SESSION_KEY);
    location.reload();
};

/**
 * Middleware operation
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 */
// export const middleware = new ApolloLink((operation, forward) => {
//     /**
//      * If session data exist in local storage, set value as session header.
//      */
//     const session = localforage.getItem(LS_SESSION_KEY);
//     console.log('Apollo.middleware:', session);
//     if (session) {
//         operation.setContext(({headers = {}}) => ({
//             headers: {
//                 [WOO_COMMERCE_HEADER]: `Session ${session}`,
//             },
//         }));
//     }
//
//     return forward(operation);
// });

export const middleware = setContext(async () => {
    /**
     * If session data exist in local storage, set value as session header.
     */
    const session = await localforage.getItem(LS_SESSION_KEY);
    // console.log('Apollo.middleware:', session);
    const headers = session
        ? {
              [WOO_COMMERCE_HEADER]: `Session ${session}`,
          }
        : {};
    return {
        headers,
    };
});

/**
 * Afterware operation
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) => {
    return forward(operation).flatMap(response => {
        /**
         * Check for session header and update session in local storage accordingly.
         */
        const context = operation.getContext();
        const {
            response: {headers},
        } = context;
        const session = headers.get(WOO_COMMERCE_HEADER);
        return fromPromise(localforage.getItem(LS_SESSION_KEY)).map(storedSession => {
            // console.log('Apollo.afterware:', session, storedSession);
            if (session) {
                // remove session data if session destroyed.
                if ('false' === session) {
                    localforage.removeItem(LS_SESSION_KEY);
                    // update session new data if changed.
                } else if (storedSession !== session) {
                    localforage.setItem(LS_SESSION_KEY, headers.get(WOO_COMMERCE_HEADER));
                }
            }
            return response;
        });
    });
});

const logoutLink = onError(({graphQLErrors}) => {
    const hasTokenExpired = hasNetworkTokenExpiredError(graphQLErrors);
    // console.log('logoutLink:', hasTokenExpired, graphQLErrors);
    if (hasTokenExpired) {
        logout().then(() => console.log('Logout'));
    }
    console.log('Error occurred:', graphQLErrors);
    // if (networkError.statusCode === 401) logout();
});

const client = new ApolloClient({
    link: logoutLink.concat(middleware.concat(afterware.concat(httpLink))),
    cache,
    resolvers: {},
});

client.onResetStore(async () => cache.writeData({data: initData}));

export default client;
