import {createMuiTheme} from '@material-ui/core/styles';

export const ThemeConfig = {
    THUMB_HEIGHT: 250,
};

export const ThumbnailSize = {
    XS: 65,
    SM: 150,
    MD: 250,
    LG: 350,
    XL: 500,
};

export const BorderRadius = {
    XS: 4,
    SM: 8,
    DEFAULT: 30,
};

export enum Colors {
    BLACK = '#000000',
    LIGHTER_GRAY = '#F1F1F1',
    LIGHT_GRAY = '#E6E6E6',
    GRAY = '#AAAAAA',
    WHITE = '#FFFFFF',
    INDEX_HERO = '#D18681',
}

const PrimaryPalette = {
    light: '#b5a588',
    main: '#9D8661',
    dark: '#4e4330',
};

const SecondaryPalette = {
    light: '#e9c7c1',
    main: '#9d6861',
    dark: '#6b3a36',
};

// A custom theme for this app
const index = createMuiTheme({
    typography: {
        fontFamily: ['Dosis'].join(','),
        fontSize: 15,
        htmlFontSize: 16,
        h1: {
            fontSize: '4rem',
            fontWeight: 500,
        },
        h2: {
            fontSize: '3rem',
            fontWeight: 500,
        },
        h3: {
            fontSize: '2.5rem',
            fontWeight: 500,
        },
        h4: {
            fontSize: '2rem',
            fontWeight: 500,
        },
        h6: {
            fontWeight: 400,
        },
    },
    shape: {
        borderRadius: 30,
    },
    palette: {
        primary: {
            ...PrimaryPalette,
        },
        secondary: {
            ...SecondaryPalette,
        },
        background: {
            default: '#fff',
        },
    },
    overrides: {
        MuiDialog: {
            root: {
                borderRadius: BorderRadius.XS,
            },
            paper: {
                borderRadius: BorderRadius.XS,
            },
        },
        MuiMenu: {
            paper: {
                borderRadius: BorderRadius.XS,
            },
        },
        // Style sheet name ⚛️
        MuiBreadcrumbs: {
            // Name of the rule
            root: {
                fontSize: 14,
                color: PrimaryPalette.main,
                textTransform: 'uppercase',
            },
            li: {
                color: PrimaryPalette.main,
                '&:hover': {
                    color: PrimaryPalette.dark,
                },
            },
        },
        MuiTextField: {
            root: {
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderRadius: BorderRadius.XS,
                    },
                },
            },
        },
    },
});

export default index;
